<template>
  <div>
    <main>
      <v-container grid-list-xl class="content-header">
        <v-flex sm12 md8 offset-md2 class="text-xs-center">
          <v-card class="pa-4">
            <h1>{{ title }}</h1>
            <div class="text-xs-left custom-body-html pa-2" v-html="body"></div>
          </v-card>
        </v-flex>
      </v-container>
    </main>
  </div>
</template>

<script>
import terms from './terms-and-privacy/terms'
import privacy from './terms-and-privacy/privacy'

export default {
  data: () => ({
    title: '',
    body: ''
  }),
  watch: {
    $route() {
      this.setContent()
    }
  },
  created() {
    this.setContent()
  },
  methods: {
    setContent() {
      this.title = this.$route.name === 'terms' ? this.$lang.header.tearmsConditions : this.$lang.header.cookiePrivacy
      this.body = this.$route.name === 'terms' ? terms : privacy
    }
  }
}
</script>

<style lang="scss">
  main {
    display: flex;
    align-items: center;
  }
  .custom-body-html {
    .content > ol > li {
      padding: 0.5rem 0;
      li {
        padding: 0.2rem 0rem;
      }
    }
    .content > ul > li {
      padding: 1rem 0;
      li {
        padding: 0.2rem 0rem;
      }
    }
    ul, ol {
      padding-left: 0.5rem;
    }
    h4 {
      font-size: 1.5rem;
      margin: 0.6rem 0 1.2rem 0;
      line-height: 1.2;
    }
  }
</style>
