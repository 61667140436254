const data = {
  // FOR en lang !
  en: `
    <div class="content">
        <h4>Privacy text</h4>
    </div>
`
}

export default data.en
